import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {
  DeleteStatus,
  exportAsExcelFile,
  formatFilter,
  ItemMode,
  ListStatus,
  MasterDataStatus,
  REGEX,
  SharedService
} from '../../../shared/shared.service';
import {ItemComponent} from '../item/item.component';
import {ProxyDynamicService} from '../../../shared/service/proxy-dynamic.service';
import {DatePipe} from '@angular/common';
import moment from 'moment';
import {Building} from '../../../shared/models/building.model';
import {Project} from '../../../shared/models/project.model';

@Component({
  selector: 'app-buildings',
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss',
  providers: [DatePipe]
})
export class ListComponent implements OnInit {
  listStatus = ListStatus;
  formConfig: any[] = [];
  formFilterConfig: any[] = [];
  filter: any = {
    limit: 15,
    page: 0,
    sort: '-code',
    code: null,
    name: null,
    project: null,
    status: MasterDataStatus.Active
  };
  source: any[] = [];
  totalRows: number = 0;

  constructor(
      private translate: TranslateService,
      private modalService: NzModalService,
      private noti: NzNotificationService,
      private service: ProxyDynamicService,
      private datePipe: DatePipe,
      private sharedService: SharedService,
  ) {
  }

  ngOnInit() {
    this.getListProject();
  }

  getListProject() {
    this.sharedService.showLoading(true);
    this.service.search('project').then((data: Project[]) => {
      const listProject = data.map((item: any) => {
        item['text'] = item.code + ' - ' + item.name;
        item['value'] = item.id;
        return item;
      });
      this.formConfig = [
        {name: 'code', title: 'building.code', default: null,  type: 'textAsLink', max: 25, regex: REGEX.code, col: 12, required: true, sortBy: 'ASC', hint: true, hintLabel: 'input-hint.code'},
        {name: 'name', title: 'building.name', default: null, type: 'text', max: 200, col: 12, required: true, sortBy: 'ASC'},
        {name: 'project', title: 'project.label', default: null, type: 'dropdown', col: 12, required: true,
          options: listProject.filter((r: any) => r.status === MasterDataStatus.Active && r.deleted === DeleteStatus.NO), sortBy: 'ASC'},
        {name: 'status', title: 'status.title', default: MasterDataStatus.Active, type: 'status', col: 12, options: this.listStatus, required: true, sortBy: 'ASC'},
        {name: 'creator', title: 'creator', default: null, type: 'text', col: 12, viewOnly: true},
        {name: 'date_created', title: 'created', default: moment().set({h: 0, m: 0, s: 0, ms: 0}).toDate(), type: 'date', col: 12, viewOnly: true, format: 'DD/MM/YYYY hh:mm:ss', showTime: true},
        {name: 'updater', title: 'updater', default: null, type: 'text', col: 12, viewOnly: true},
        {name: 'date_updated', title: 'updated', default: moment().set({h: 0, m: 0, s: 0, ms: 0}).toDate(), type: 'date', col: 12, viewOnly: true, format: 'DD/MM/YYYY hh:mm:ss', showTime: true},
      ];
      this.formFilterConfig = [
        {name: 'code', title: 'building.code', default: '', type: 'text', regex: REGEX.code, col: 6},
        {name: 'name', title: 'building.name', default: '', type: 'text', col: 6},
        {name: 'project', title: 'project.label', default: null, type: 'dropdown', col: 6, options: listProject.filter((r: any) => r.deleted === DeleteStatus.NO)},
        {name: 'status', title: 'status.title', default: MasterDataStatus.Active, type: 'status', col: 6, options: this.listStatus},
      ];
      this.sharedService.showLoading(false);
      this.getData(this.filter);
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  getData(event?: any) {
    this.sharedService.showLoading(true);
    if (event) {
      Object.keys(event).forEach((key: any) => {
        this.filter[key] = event[key];
      })
    }
    const filter = formatFilter(this.filter, this.formFilterConfig);
    this.service.searchWithMeta('building', {
      fields: ['*', 'user_created.*', 'user_updated.*'],
      filter: filter,
      "limit": this.filter.limit,
      "page": this.filter.page,
      'sort': [this.filter['sort']]
    }).then(res => {
      this.totalRows = res.total;
      this.source = res?.data?.map((item: Building) => {
        item['creator'] = item['user_created'] ? [item['user_created']['first_name'], item['user_created']['last_name']].join(' ') : undefined;
        item['updater'] = item['user_updated'] ? [item['user_updated']['first_name'], item['user_updated']['last_name']].join(' ') : undefined;
        return item;
      });
      this.sharedService.showLoading(false);
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  onChangePage(event: any) {
    this.filter.page = event;
    this.getData();
  }

  onChangeSize(event: any) {
    this.filter.limit = event;
    this.getData();
  }

  edit(item: any) {
    const modalRef = this.modalService.create({
      nzContent: ItemComponent,
      nzWidth: '50vw',
      nzClassName: 'modal-building',
      nzData: {
        mode: ItemMode.Edit,
        formConfig: this.formConfig,
        data: item,
        collection: 'building'
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
            this.translate.instant('updateSuccess', {value: (this.translate.instant('building.label'))}));
        this.getData();
      }
    });
  }

  view(item: any) {
    const modalRef = this.modalService.create({
      nzContent: ItemComponent,
      nzWidth: '50vw',
      nzClassName: 'modal-building',
      nzData: {
        mode: ItemMode.View,
        formConfig: this.formConfig,
        data: item,
        collection: 'building'
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
            this.translate.instant('updateSuccess', {value: (this.translate.instant('building.label'))}));
        this.getData();
      }
    });
  }

  add() {
    const modalRef = this.modalService.create({
      nzContent: ItemComponent,
      nzWidth: '50vw',
      nzClassName: 'modal-building',
      nzData: {
        mode: ItemMode.Add,
        formConfig: this.formConfig,
        collection: 'building'
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
            this.translate.instant('addSuccess', {value: (this.translate.instant('building.label'))}));
        this.getData();
      }
    });
  }

  delete(item: any) {
    this.service.deleteItemBuilding(item.id).subscribe(res => {
      this.noti.create('success', '',
          this.translate.instant('deleteSuccess', {value: (this.translate.instant('building.label'))}));
      this.getData();
    }, (error: any) => {
      this.service.handleError(error)
    });
  }

  export() {
    this.sharedService.showLoading(true);
    this.service.search('building', {
      fields: ['*', 'user_created.*', 'user_updated.*'],
      filter: formatFilter(this.filter, this.formFilterConfig),
    }).then(dataResult => {
      let configColumn = this.formConfig.map((item: any) => {
        return {
          headerText: item.title,
          field: item.name
        }
      })
      let listExport: any[] = [];
      if(dataResult?.length > 0) {
        dataResult.map(item => {
          item['creator'] = item['user_created'] ? [item['user_created']['first_name'], item['user_created']['last_name']].join(' ') : undefined;
          item['updater'] = item['user_updated'] ? [item['user_updated']['first_name'], item['user_updated']['last_name']].join(' ') : undefined;
          return item;
        }).forEach((data: any) => {
          let item: any = {};
          configColumn.forEach(e => {
            item[this.translate.instant(e.headerText)] = data[e.field] || '';
            if (e.field === 'date_created' || e.field === 'date_updated') {
              item[this.translate.instant(e.headerText)] = this.datePipe.transform(data[e.field], 'DD/MM/YYYY ');
            }
          })
          listExport.push(item);
        })
      } else {
        let item: any = {};
        configColumn.forEach(e => {
          item[this.translate.instant(e.headerText)] = '';
          listExport.push(item);
        })
      }
      exportAsExcelFile(listExport, this.translate.instant('building.title') + '_' + this.datePipe.transform(new Date(), 'yyMMdd'));
      this.sharedService.showLoading(false);
    }, (error: any) => {
      this.service.handleError(error)
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  onSort(event: any) {
    this.formConfig.forEach((col) => {
      if (col['name'] === event) {
        this.filter['sort'] = col['sortBy'] === 'ASC' ? event : '-' + event;
        col['sortBy'] = col['sortBy'] === 'ASC' ? 'DESC' : 'ASC';
      } else {
        col['sortBy'] = 'ASC'
      }
    });
    this.getData();
  }
}
