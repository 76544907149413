import {AfterViewInit, Component, inject, ViewChild} from '@angular/core';
import {ItemMode, patchFormValue} from '../../../shared/shared.service';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import {FormInputDynamicComponent} from '../../../shared/component/form-input-dynamic/form-input-dynamic.component';
import moment from 'moment';

@Component({
  selector: 'app-building-item',
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class ItemComponent implements AfterViewInit {
  readonly nzModalData = inject(NZ_MODAL_DATA);
  formConfig = [];
  itemMode = ItemMode;
  @ViewChild('formModify') formModify!: FormInputDynamicComponent;

  constructor(
    private modal: NzModalRef,
  ) {
    this.formConfig = this.nzModalData.formConfig;
  }

  ngAfterViewInit() {
    this.formModify.mode = this.nzModalData.mode;
    if (this.nzModalData.mode === ItemMode.View) {
      patchFormValue(this.formModify.formGroup, this.nzModalData.data);
      this.formModify.f['date_created'].setValue(this.nzModalData.data.date_created ? moment(this.nzModalData.data.date_created, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null)
      this.formModify.f['date_updated'].setValue(this.nzModalData.data.date_updated ? moment(this.nzModalData.data.date_updated, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null)
      this.formModify.formGroup.disable();
    } else {
      if (this.nzModalData.mode === ItemMode.Edit) {
        patchFormValue(this.formModify.formGroup, this.nzModalData.data);
        this.formModify.f['date_created'].setValue(this.nzModalData.data.date_created ? moment(this.nzModalData.data.date_created, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null)
        this.formModify.f['date_updated'].setValue(this.nzModalData.data.date_updated ? moment(this.nzModalData.data.date_updated, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null)
        this.formModify.formGroup.enable();
      }
    }
  }

  edit() {
    this.formModify.mode = ItemMode.Edit;
    patchFormValue(this.formModify.formGroup, this.nzModalData.data);
    this.formModify.f['date_created'].setValue(this.nzModalData.data.date_created ? moment(this.nzModalData.data.date_created, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null)
    this.formModify.f['date_updated'].setValue(this.nzModalData.data.date_updated ? moment(this.nzModalData.data.date_updated, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null)
    this.formModify.formGroup.enable();
  }

  destroyModal() {
    this.modal.destroy()
  }

  onSave() {
    this.formModify.collection = this.nzModalData.collection;
    this.formModify.onSave();
  }

  onSaveDone() {
    this.modal.destroy(true);
  }
}
